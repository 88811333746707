import React, { Component } from 'react'
import Layout from '../components/layout/Layout'
import Intro from '../components/Intro'

class App extends Component {
  render() {
    return (
      <Layout backgroundImage={false}>
        <Intro />
      </Layout>
    )
  }
}

export default App
